import styled, { css } from 'styled-components';
import * as PropTypes from "prop-types";
import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Bar, BarChart, Brush, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis
} from 'recharts';
import Loader from '../Loader/Loader';
import moment from "moment";
import { CustomLegend } from "../../views/UsersPage/Components/CustomLegend";


export const Paper = styled.div`
  width: 100%;
  margin-bottom: 22px;
  margin-top: 30px;
  box-shadow: ${(props) => props.theme.boxShadow};
  background-color: ${(props) => props.theme.color.pageLightBg};
  padding: 30px;
`;

export const Title = styled.h1`
  margin: 10px;
  text-align: left;
  color: ${(props) => props.color};
  border-bottom: 1px solid ${(props) => props.color};
`;

export const Subtitle = styled.h2`
  margin: 10px;
  text-align: left;
  color: ${(props) => props.color};
  border-bottom: 1px solid ${(props) => props.color};
`;

export const SubtitleWithoutBorder = styled.h2`
  margin: 10px;
  text-align: left;
  color: ${(props) => props.color};
`;

export const Text = styled.h4`
  margin: 10px;
  text-align: left;
  color: ${(props) => props.color};
`;

export const DatePickersBar = styled(Flex)`
  border-top: 1px solid #d5d5d5;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
`;

export const StyledInput = styled.input`
  border: 0px;
  height: 36px;
  width: ${props => props.width ? props.width : "100%"};
  border-radius: 5px;
  margin-left: 10px;
  :focus {
    outline: 0;
  };
  padding: 5px 10px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
`;

export const Picker = styled(Flex)`
  align-items: flex-end;

  .react-datepicker-wrapper {
    max-width: 170px;

    input {
      border: none;
      text-align: right;
    }
  }
  ${({ right }) => {
    if (right) {
      return '.MuiInputLabel-formControl { left: auto; right: 0 }';
    }
    return null;
  }}
`;


export const FilterBox = styled(Flex)`
  flex: 1 1 30%;
  align-items: center;
  margin: 0 10px;

  ${props => props.center && css`
    justify-content: center;
  `}
`;

export const Filters = styled(Flex)`
  margin: 30px;
  justify-content: space-between;
`;

export const customDropdownStyles = {
  container: ({ provided }) => ({
    ...provided,
    width: '100%',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    background: '#fff',
    borderRadius: 5,
    marginLeft: 10,
    position: 'relative'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '100%',
    display: 'flex',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  menu: provided => ({ ...provided, zIndex: 9999 })
};

export const customCompaniesDropdownStyles = {
  ...customDropdownStyles,
  menu: (provided) => ({
    ...provided,
    width: 500,

  }),
};

export const BarChartLoader = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Option = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: flex;
  padding: ${({ single }) => (single ? '10px 15px' : 0)};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    display: ${({ isFocused }) => (isFocused ? 'block' : 'none')};
    pointer-events: none;
    background-color: rgba(0,0,0,.05);
    z-index: 2;
  }

  & + & {
    border-top: 1px ${({ company }) => (company ? '#999' : '#ddd')} solid;
  }

  &:hover, &:focus {
    &:before {
      display: block;
    }
  }
`;

export const BaseOption = ({
  innerProps, isDisabled, isFocused, data
}) => (
  <Option {...innerProps} single isDisabled={isDisabled} isFocused={isFocused}>
    {data.label}
  </Option>
);

BaseOption.propTypes = {
  innerProps: PropTypes.any,
  isDisabled: PropTypes.boolean,
  isFocused: PropTypes.boolean,
  data: PropTypes.any
}


export const steps = [
  { value: '5m', label: '5 minut' },
  { value: '1H', label: 'godzina' },
  { value: '1D', label: 'dzień' }
];

export const mediums = [
  { value: 'ios', label: 'iOS' },
  { value: 'web', label: 'Web' },
  { value: 'android', label: 'Android' }
];

export const chartColors = [
  '#5ca074',
  '#42356e',
  '#b0874f',
  '#4895c6',
  '#843f27',
  '#9d87ca',
  '#4b6428',
  '#8c528c',
  '#cf7485',
  '#732844'
];

export const funnelColors = [
  '#8fd14f',
  '#cee741',
  '#fef446',
  '#fac711',
];

export const showLoaderOrPlaceHolder = (loadingChartData, isFilter = true) => {
  const message = isFilter ? "Brak danych, proszę użyć filtrów" : "Brak danych"
  if (loadingChartData) return (<BarChartLoader><Loader /></BarChartLoader>);
  return (<BarChartLoader><Text>{message}</Text></BarChartLoader>);
};

export const showBarChart = (data, barDataKey, dataKey, showBrush, dateFormatter) => {
  return (
    <BarChart
      data={data}
      margin={{
        top: 5, right: 30, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
      <XAxis dataKey={dataKey} stroke="#aaaaaa" tickFormatter={dateFormatter} />
      <YAxis stroke="#aaaaaa" />
      <Tooltip labelFormatter={dateFormatter} />
      <Bar dataKey={barDataKey} fill="#212121" />
      {showBrush && <Brush dataKey={dataKey} stroke="#212121" />}
      <Legend wrapperStyle={{ paddingTop: '10px' }} />
    </BarChart>
  );
};

export const showLineChart = (data, barDataKey, dataKey, showBrush, name, color, dateFormatter, customLegend) => {
  return (
    <LineChart
      data={data}
      margin={{
        top: 5, right: 30, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
      <XAxis dataKey={dataKey} stroke="#aaaaaa" tickFormatter={dateFormatter} />
      <YAxis stroke="#aaaaaa" />
      <Tooltip formatter={(value) => value.toFixed(2)} labelFormatter={dateFormatter} />
      <Line dataKey={barDataKey} name={name} stroke={color} />
      {showBrush && <Brush dataKey={dataKey} stroke="#212121" />}
      <Legend content={customLegend && <CustomLegend />} wrapperStyle={{ paddingTop: '10px' }} />
    </LineChart>
  );
};

export const formatDate = (date) => moment(date).format('YYYY-MM-DD')





