import React, { useEffect, useState } from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
import { currencyFormatter, percentFormatter } from "../../../components/helpers/utils";
import { Filters } from "../../../components/helpers/chartHelpers";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import moment from "moment";

const columns = [
  { id: 'mask', label: 'Maska', minWidth: 100 },
  { id: 'monthPurchaseValue', label: 'Suma sprzedaż', minWidth: 100, format: currencyFormatter.format },
  { id: 'monthDiscountsValue', label: 'Suma rabatów', minWidth: 100, format: currencyFormatter.format },
  { id: 'discountsPercent', label: 'Procent kwotowy rabatu', minWidth: 100, format: percentFormatter.format },
  { id: 'monthPurchaseCountValue', label: 'Ilość transakcji', minWidth: 100 },
  { id: 'previousMonthDiscountsValue', label: 'Suma rabatów (poprzedni okres)', minWidth: 100, format: currencyFormatter.format },
];

function DiscountVsPurchasesPerMaskTable({ discountStore }) {

  const { discountsVsPurchasePerMask, loadingDiscountsVsPurchasePerMask, fetchDiscountsVsPurchasesPerMask } = discountStore;

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(async () => {
    await fetchDiscountsVsPurchasesPerMask(startDate, endDate);
  }, [startDate, endDate])

  return (
    <>
    <div style={{ width: '50%', margin: 'auto' }}>
      <Filters>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onEndDateChange={setEndDate}
          onStartDateChange={setStartDate}
          showTime={false}
        />
      </Filters>
    </div>
    <TableWithJson
      columns={columns}
      data={discountsVsPurchasePerMask}
      loadingData={loadingDiscountsVsPurchasePerMask}
      withPagination={false}
      showJson={false}
    />
    </>
  );
}

export default inject('discountStore')(observer(DiscountVsPurchasesPerMaskTable));

DiscountVsPurchasesPerMaskTable.propTypes = {
  discountStore: PropTypes.shape({
    discountsVsPurchasePerMask: PropTypes.array,
    loadingDiscountsVsPurchasePerMask: PropTypes.bool,
    fetchDiscountsVsPurchasesPerMask: PropTypes.func
  })
};