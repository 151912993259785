import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import React from 'react';

export const ImageButton = (setImageOpen, setImageUrl, value) => (
  <Button onClick={() => {setImageOpen(true); setImageUrl(value); }}>
    <img style={{ maxWidth: 100, maxHeight: 100 }} src={value} />
  </Button>
);

ImageButton.propTypes = {
  setImageOpen: PropTypes.func,
  setImageUrl: PropTypes.func,
  value: PropTypes.string,
};