import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import VerticalBarChart from "./VerticalBarChart";
import {
  Filters,
} from "../../../components/helpers/chartHelpers";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import moment from "moment";

const keyNames = ['sum', 'shadow']
const keyTranslations = ['Ilość transakcji na maskę', 'W zeszłym miesiącu']
const keyColors = ["#272727", "#868686"];

const PurchasesPerMaskChart = inject("purchaseStore")(observer(({ purchaseStore }) => {
  const { loadingPurchasesPerMask, purchasesPerMask, fetchPurchasesPerMask, shortcutFrom, shortcutTo } = purchaseStore;
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    if(startDate && endDate) {
      fetchPurchasesPerMask(startDate, endDate)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if(shortcutFrom && shortcutTo) {
      setStartDate(shortcutFrom)
      setEndDate(shortcutTo)
    }
  }, [shortcutFrom, shortcutTo])

  return(
     <>
       <VerticalBarChart
         loadingData={loadingPurchasesPerMask}
         chartData={purchasesPerMask}
         keyNames={keyNames}
         dataKey='_id'
         keyTranslations={keyTranslations}
         keyColors={keyColors}
       />
       <div style={{ width: '55%', margin: 'auto' }}>
         <Filters>
           <DateRangePicker
             startDate={startDate}
             endDate={endDate}
             onEndDateChange={setEndDate}
             onStartDateChange={setStartDate}
             showTime={false}
           />
         </Filters>
       </div>
     </>);
}));

export default PurchasesPerMaskChart;

PurchasesPerMaskChart.propTypes = {
}