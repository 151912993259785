import {
  showLoaderOrPlaceHolder, StyledInput
} from "../../../components/helpers/chartHelpers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";
import Funnel from "./Funnel";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

const CustomerJourneyFunnelChart = inject("aggregationsStore")(observer(({ aggregationsStore }) => {
  const { shortcutFrom: from, shortcutTo: to } = aggregationsStore;
  const timeFrom = from ? moment(from).toDate() : moment().startOf('month').toDate();
  const timeTo = to ? moment(to).toDate() : moment().toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);
  const { loadingFunnelChartData, funnelChartData, fetchFunnelChartData } = aggregationsStore;

  useEffect(() => {
    fetchFunnelChartData(startDate.toISOString(), endDate.toISOString());
  }, [startDate, endDate]);

  useEffect(() => {
    if (from && to) {
      setStartDate(from);
      setEndDate(to);
    }
  }, [from, to]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {loadingFunnelChartData || funnelChartData.length === 0 ? (
          <div style={{ margin: 20, height: 300 }}>{showLoaderOrPlaceHolder(loadingFunnelChartData)}</div>
        ) : <Funnel data={funnelChartData}/>}
      </GridItem>
      <GridItem xs={6} style={{ textAlign: 'right', paddingRight: '75px', marginTop: '30px' }}>
        <div>
          Od:
          <PolishDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<StyledInput />}
          />
        </div>
      </GridItem>
      <GridItem xs={6} style={{ textAlign: 'left', paddingLeft: 0, marginTop: '30px' }}>
        <div style={{ marginLeft: 30 }}>
          Do:
          <PolishDatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            customInput={<StyledInput />}
          />
        </div>
      </GridItem>
    </GridContainer>
  );
}));

export default CustomerJourneyFunnelChart;

CustomerJourneyFunnelChart.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
  aggregationsStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}