import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import moment from 'moment';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import Loader from '../../../components/Loader/Loader';
import { DatePickerContainer } from '../../ProductsPage/Components/ProductsMaskSelect';

function MaskSelectDateRange ({ bannersStore }) {
  const { loadingAvailableMasksDateRange, availableMasksDateRange, setChosenDateRangeMasks, fetchBannersTopDateRange, chosenDateRangeMasks, fetchMasksForDateRange } = bannersStore

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    fetchMasksForDateRange(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    if(chosenDateRangeMasks.length > 0) {
      let mask = null;
      for(mask of chosenDateRangeMasks) {
        fetchBannersTopDateRange(mask, startDate, endDate);
      }
    }
  }, [chosenDateRangeMasks, startDate, endDate])

  return (
  <div style={{ display: 'flex' }}>
    <div style={{ width: '50%' }}>
      <Select
        isMulti
        isLoading={loadingAvailableMasksDateRange}
        placeholder="Wybierz maski..."
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
        name="colors"
        options={availableMasksDateRange}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        onChange={(option) => {
          setChosenDateRangeMasks(option)
        }}
      />
    </div>
    <div style={{ width: '50%' }}>
      <DatePickerContainer style={{ justifyContent: 'space-between', margin: 1 }}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onEndDateChange={setEndDate}
          onStartDateChange={setStartDate}
          showTime={false}
        />
      </DatePickerContainer>
    </div>
    <div>
      {loadingAvailableMasksDateRange && <Loader py={0} />}
    </div>
  </div>
    )
}

export default inject('bannersStore')(observer(MaskSelectDateRange));

MaskSelectDateRange.propTypes = {
  bannersStore: PropTypes.shape({
    availableMasksDateRange: PropTypes.array,
    chosenDateRangeMasks: PropTypes.array,
    loadingAvailableMasksDateRange: PropTypes.bool,
    setChosenDateRangeMasks: PropTypes.func,
    fetchBannersTopDateRange: PropTypes.func,
    fetchMasksForDateRange: PropTypes.func,
  })
};