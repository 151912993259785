import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { inject, observer } from "mobx-react";
import {
  showLoaderOrPlaceHolder, Filters, FilterBox, StyledInput
} from "../../../components/helpers/chartHelpers";
import moment from "moment";
import * as PropTypes from "prop-types";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";
import PercentTooltip from "./PercentTooltip";
import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const UsersPieChart = inject("usersStore")(observer(({ usersStore }) => {
  const [startDate, setStartDate] = useState( moment().subtract(1, 'day').startOf('day').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const { usersPieChartData, loadingUsersPieChartData, fetchUsersPieChartData } = usersStore;

  useEffect(() => {
    fetchUsersPieChartData(startDate.toISOString(), endDate.toISOString());
  }, [startDate, endDate]);

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        {loadingUsersPieChartData || usersPieChartData.length === 0 ? (
          showLoaderOrPlaceHolder(loadingUsersPieChartData)
        ) : (
          <PieChart width={400} height={400}>
            <Tooltip content={<PercentTooltip />} />
            <Legend wrapperStyle={{ paddingBottom: '10px' }}/>
            <Pie
              data={usersPieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              nameKey="medium"
            >
              {usersPieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index%COLORS.length]}/>
              ))}
            </Pie>
          </PieChart>
        )}
      </ResponsiveContainer>
      <Filters>
        <FilterBox center>
          Dzień:
          <PolishDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setEndDate(moment(date).add(1, 'day').toDate())
            }}
            customInput={<StyledInput />}
          />
          <TooltipUI style={{ paddingTop: 3, marginLeft: 20 }} title="Suma unikalnych urządzeń dla danego dnia dla danej aplikacji">
            <InfoIcon htmlColor="#212121"/>
          </TooltipUI>
        </FilterBox>
      </Filters>
    </>
  );
}));

export default UsersPieChart;

UsersPieChart.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
  usersStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}