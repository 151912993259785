import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import PropTypes from "prop-types";

export const CustomLegend = (props) => (
  <div>
    <ul style={{
      padding: 0,
      margin: 0,
      textAlign: 'center',
      listStyle: 'none'
    }}
    >
      {props.payload.map((data, index) => (
        <li key={index} style={{ display: 'inline-block', marginRight: 10 }}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 4 }}
          >
            <path stroke="none" fill={data.color} d="M0,4h32v24h-32z" />
          </svg>
          <span>{data.value}</span>
          { data.value === 'RSI'
          && (
            <TooltipUI style={{ paddingTop: 10, marginLeft: 6 }} title="Wskaźnik siły względnej – oscylator określający siłę trendu w analizie technicznej.">
              <InfoIcon htmlColor="#212121"/>
            </TooltipUI>
          ) }
          { data.value === 'MACD'
          && (
            <TooltipUI style={{ paddingTop: 10, marginLeft: 6 }} title="MACD (Moving Average Convergence / Divergence) – Wskaźnik bada zbieżności i rozbieżności średnich ruchomych. Jest różnicą wartości długoterminowej i krótkoterminowej średniej wykładniczej.">
              <InfoIcon htmlColor="#212121"/>
            </TooltipUI>
          ) }
        </li>
      ))}
    </ul>
  </div>
);

CustomLegend.propTypes = {
  payload: PropTypes.any
}
