import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import UsersBarChart from "./Components/UsersBarChart";
import UsersPieChart from "./Components/UsersPieChart";
import { Title } from "../../components/helpers/chartHelpers";
import UsersTable from "./Components/UsersTable";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import UsersByMediumCharts from "./Components/UsersByMediumCharts";
import TimeframeShortcuts from "../../components/TimeframeShortcuts/TimeframeShortcuts";
import PurchaseSumCard from "./Components/PurchaseSumCard";
import ChurnLineChart from "./Components/ChurnLineChart";
import UserBarByMediumCharts from "./Components/UserBarByMediumCharts";
import PushLineChart from './Components/PushLineChart';

@observer
@inject('usersStore')
class UsersPageContainer extends Component {

  componentDidMount() {
    this.props.usersStore.fetchActions();
  }

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Użytkownicy</Title>
          </GridItem>
          <GridItem xs={5}>
            <UsersPieChart />
          </GridItem>
          <GridItem xs={7}>
            <UsersBarChart />
          </GridItem>
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '10px 10px' }} />
          <GridItem xs={12}>
          <UserBarByMediumCharts />
          </GridItem>
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '10px 10px' }} />
          <GridItem xs={12}>
            <UsersByMediumCharts />
          </GridItem>
          {/*<GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '0 10px' }} />*/}
          {/* <UsersCircularStats />*/}
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '0px 10px 30px 10px' }} />
          <PushLineChart />
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '0px 10px 30px 10px' }} />
          <ChurnLineChart />
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '0 10px' }} />
          <GridItem xs={2}>
            <TimeframeShortcuts />
          </GridItem>
          <GridItem xs={10}>
            <UsersTable  />
          </GridItem>
          <GridItem xs={12}>
            <PurchaseSumCard />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default UsersPageContainer;

UsersPageContainer.propTypes = {
  usersStore: PropTypes.shape({
    fetchActions: PropTypes.func
  }),
};
