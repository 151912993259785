import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import VerticalBarChart from "./VerticalBarChart";
import { Filters } from "../../../components/helpers/chartHelpers";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import moment from "moment";

const keyNames = ['value', 'shadow']
const keyTranslations = ['Średnia wartość koszyka', 'W zeszłym miesiącu']
const keyColors = ["#272727", "#868686"];

const AvgTotalPricePerMaskChart = inject("purchaseStore")(observer(({ purchaseStore }) => {
  const { loadingAvgTotalPricePerMask, avgTotalPricePerMask, fetchAvgTotalPricePerMask, shortcutFrom, shortcutTo } = purchaseStore;

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    if(startDate && endDate) {
      fetchAvgTotalPricePerMask(startDate, endDate)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if(shortcutFrom && shortcutTo) {
      setStartDate(shortcutFrom)
      setEndDate(shortcutTo)
    }
  }, [shortcutFrom, shortcutTo])

  return(
    <>
      <VerticalBarChart
        loadingData={loadingAvgTotalPricePerMask}
        chartData={avgTotalPricePerMask}
        keyNames={keyNames}
        dataKey='_id'
        keyTranslations={keyTranslations}
        keyColors={keyColors}
        labelFormatter={(value) => `${value.toFixed(2)} zł`}
      />
      <div style={{ width: '55%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
    </>
  );
}));

export default AvgTotalPricePerMaskChart;

AvgTotalPricePerMaskChart.propTypes = {
}