import React  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";

function TopProductsPerMaskTable({ productsStore }) {

  const { topProductsPerMask, loadingTopProductsPerMask, columnsPerMask } = productsStore;

  return (
    <TableWithJson
      columns={columnsPerMask}
      data={topProductsPerMask}
      loadingData={loadingTopProductsPerMask}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('productsStore')(observer(TopProductsPerMaskTable));

TopProductsPerMaskTable.propTypes = {
  productsStore: PropTypes.shape({
    topProductsPerMask: PropTypes.array,
    columnsPerMask: PropTypes.array,
    loadingTopProductsPerMask: PropTypes.bool
  })
};