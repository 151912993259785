import { ResponsiveContainer } from 'recharts'
import {
  BaseOption,
  steps,
  customDropdownStyles,
  FilterBox,
  Filters,
  showBarChart,
  showLoaderOrPlaceHolder,
  StyledInput,
} from '../../../components/helpers/chartHelpers'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { inject, observer } from 'mobx-react'
import * as PropTypes from 'prop-types'
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker'
import { Typography } from '@material-ui/core'

const UsersBarChart = inject('usersStore')(
  observer(({ usersStore, medium }) => {
    const { shortcutFrom: from, shortcutTo: to } = usersStore
    const timeFrom = from
      ? moment(from).toDate()
      : moment().subtract(1, 'day').toDate()
    const timeTo = to ? moment(to).toDate() : moment().toDate()
    const [startDate, setStartDate] = useState(timeFrom)
    const [endDate, setEndDate] = useState(timeTo)
    const [step, setStep] = useState('5m')
    const {
      usersBarChartData,
      fetchUsersBarChartData,
      loadingUsersBarChartData,
      fetchUsersByMediumBarChartData,
      usersByMediumBarChartDataMobile,
      loadingUsersByMediumBarChartDataMobile,
    } = usersStore

    useEffect(() => {
      if (medium) {
        fetchUsersByMediumBarChartData(
          startDate.toISOString(),
          endDate.toISOString(),
          step,
          medium.value,
          true
        )
      } else {
        fetchUsersBarChartData(
          startDate.toISOString(),
          endDate.toISOString(),
          step
        )
      }
    }, [startDate, endDate, step, medium])

    useEffect(() => {
      if (from && to) {
        setStartDate(from)
        setEndDate(to)
      }
    }, [from, to])

    const chart = (chartData, loading) => {
      return loading
        ? showLoaderOrPlaceHolder(loading)
        : showBarChart(chartData, 'value', 'fromTs', true, null)
    }

    return (
      <>
        {medium && (
          <Typography color='textSecondary' align='center' gutterBottom>
            {medium.label}
          </Typography>
        )}
        <ResponsiveContainer width='100%' height={300}>
          {medium
            ? chart(
                usersByMediumBarChartDataMobile[medium.value],
                loadingUsersByMediumBarChartDataMobile[medium.value]
              )
            : chart(usersBarChartData, loadingUsersBarChartData)}
        </ResponsiveContainer>
        <Filters>
          <FilterBox>
            Od:
            <PolishDatePicker
              showTimeSelect
              dateFormat='dd/MM/yyyy hh:mm'
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<StyledInput />}
            />
          </FilterBox>
          <FilterBox>
            Do:
            <PolishDatePicker
              showTimeSelect
              dateFormat='dd/MM/yyyy h:mm aa'
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<StyledInput />}
            />
          </FilterBox>
          <FilterBox>
            Przedział:
            <Select
              options={steps}
              value={steps.filter((option) => option.value === step)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={customDropdownStyles}
              components={{ Option: BaseOption }}
              onChange={(option) => setStep(option.value)}
            />
          </FilterBox>
        </Filters>
      </>
    )
  })
)

export default UsersBarChart

UsersBarChart.propTypes = {
  medium: PropTypes.any,
  from: PropTypes.any,
  to: PropTypes.any,
  usersStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any,
  }),
}
