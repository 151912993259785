export const API_ROUTES = {
  STATISTICS: "/statistics.json",
  STATISTICS_SUM: "/statistics-sum.json",
  STATISTICS_AVG: "/statistics-avg.json",
  EVENTS: "/events.json",
  ACTIONS: "/actions.json",
  STORES: "/stores.json",
  COUNTRIES: "/countries.json",
  PURCHASE_PLN_TOTAL: "/purchase-pln-total.json",
  PURCHASE: {
    HOURLY: "/purchase/hourly.json"
  },
  STATISTICS_TOP_PRODUCTS: "/statistics-top-products.json",
  STATISTICS_TOP_BANNERS: "/statistics-top-banners.json",
  TOP_CATEGORIES_BY_MASK: "/top-categories-by-mask.json",
  TOP_PRODUCTS_CATEGORIES: "/top-products-by-category.json",
  TOP_PRODUCTS_BY_MASK: "/top-products-by-mask.json",
  STATISTIC_DISTINCT_KEY: "/statistics-distinct-key.json"
};
