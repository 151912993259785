import { action, observable } from "mobx";
import config from '../config'
import moment from "moment";
import 'moment/locale/pl'
import API from "../_app/api";
import { API_ROUTES } from "../_app/routes";
import qs from "qs";
import aggregationsStore from "./aggregationsStore";
import { orderBy, sortBy } from 'lodash'
import { currencyFormatter } from "../components/helpers/utils";
import {
  generateColumnsForMergedCollectionPLBased,
  getProperValueBasedOnSortedCollections
} from "../components/utils/collectionMergingUtils";

export class ProductsStore {

  @observable orderedTopGlobalMonthValues = [];
  @observable topSkuIdsMonth = [];

  @observable topProductsGlobal = [];
  @observable loadingTopProductsGlobal = true;

  @observable topProductsPerMask = [];
  @observable loadingTopProductsPerMask = true;
  @observable columnsPerMask = [];

  @observable availableMasks = []
  @observable loadingAvailableMasks = true;

  @observable topCategories = []
  @observable loadingTopCategories = true;

  @observable topCategoriesByMask = []
  @observable availableMasksForCategories = []
  @observable loadingTopCategoriesByMask = true;
  @observable chosenCategoriesMasks = [];

  @observable availableMasksForProducts = []
  @observable loadingTopProductsPerMaskDropdown = true;
  @observable chosenProductsMasks = [];
  @observable topProductsForMask = [];

  limit = 100

  constructor() {
  }

  @action.bound fetchProductsTop = async () => {
    this.loadingTopProductsGlobal = true

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
      aggregateKey: '$value',
      limit: this.limit
    }

    let { data: month } = await API.get(`${API_ROUTES.STATISTICS_TOP_PRODUCTS}?${qs.stringify(monthQuery)}`);
    month.forEach((item, index) => item.position = index + 1)
    let topSkuIds = month.map((item) => item._id)
    topSkuIds = sortBy(topSkuIds)
    this.topSkuIds = topSkuIds;

    const productsAmountStatId = config.statIds.productsCountPerMaskPerSku;
    const productsValueStatId = config.statIds.productsValuesPerMaskPerSku;

    const todayQuery = {
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
    }

    const yesterdayQuery = {
      step: '1H',
      timeFrom: moment().subtract(1, 'days').startOf('day').toISOString() ,
      timeTo: moment().startOf('day').toISOString(),
      key: '$objects_id',
    }

    const weekQuery = {
      step: '1H',
      timeFrom: moment().subtract(7, 'days').startOf('day').toISOString() ,
      timeTo: moment().startOf('day').toISOString(),
      key: '$objects_id',
    }

    const params = {
      objects_id: topSkuIds.toString()
    }

    let { data: { sum: todayAmount } } = await aggregationsStore.fetchStatisticsSum(todayQuery.timeFrom, todayQuery.timeTo, todayQuery.step, productsAmountStatId, todayQuery.key, { params })
    let { data: { sum: todayValue } } = await aggregationsStore.fetchStatisticsSum(todayQuery.timeFrom, todayQuery.timeTo, todayQuery.step, productsValueStatId, todayQuery.key, { params })

    let { data: { sum: yesterdayAmount } } = await aggregationsStore.fetchStatisticsSum(yesterdayQuery.timeFrom, yesterdayQuery.timeTo, yesterdayQuery.step, productsAmountStatId, yesterdayQuery.key, { params })
    let { data: { sum: yesterdayValue } } = await aggregationsStore.fetchStatisticsSum(yesterdayQuery.timeFrom, yesterdayQuery.timeTo, yesterdayQuery.step, productsValueStatId, yesterdayQuery.key, { params })

    let { data: { sum: weekAmount } } = await aggregationsStore.fetchStatisticsSum(weekQuery.timeFrom, weekQuery.timeTo, weekQuery.step, productsAmountStatId, weekQuery.key, { params })
    let { data: { sum: weekValue } } = await aggregationsStore.fetchStatisticsSum(weekQuery.timeFrom, weekQuery.timeTo, weekQuery.step, productsValueStatId, weekQuery.key, { params })

    let { data: { sum: monthAmount } } = await aggregationsStore.fetchStatisticsSum(monthQuery.timeFrom, monthQuery.timeTo, monthQuery.step, productsAmountStatId, monthQuery.key, { params })
    const monthValue = orderBy(month, '_id', 'asc')
    this.orderedTopGlobalMonthValues = monthValue;
    const collectionsToMerge = {
      todayAmount,
      todayValue,
      yesterdayAmount,
      yesterdayValue,
      weekAmount,
      weekValue,
      monthAmount,
      monthValue
    }

    const indexes = {
      todayAmount: 0,
      todayValue: 0,
      yesterdayAmount: 0,
      yesterdayValue: 0,
      weekAmount: 0,
      weekValue: 0,
      monthAmount: 0,
      monthValue: 0
    }

    const result = []
    for (let i = 0; i < topSkuIds.length; i++) {
      const row = {}

      Object.keys(collectionsToMerge).forEach(collectionName => {
        const { value, index } = getProperValueBasedOnSortedCollections(collectionsToMerge[collectionName], indexes[collectionName], topSkuIds, i, '_id')
        row[collectionName] = value;
        indexes[collectionName] = index
      })

      row.productName = collectionsToMerge.monthValue[indexes.monthValue - 1].productName
      row.sku = collectionsToMerge.monthValue[indexes.monthValue - 1]._id
      row.position = collectionsToMerge.monthValue[indexes.monthValue - 1].position
      result.push(row)
    }

    this.topProductsGlobal = orderBy(result, 'monthValue', 'desc');
    this.loadingTopProductsGlobal = false
  }

  @action fetchMasksData = async () => {
    this.loadingTopProductsPerMask = true;
    const topSkuIds = this.topSkuIds;
    const availableMasks = [];

    const allMasksData = {}
    const maskIndexes = {}

    const rawMaskData = await this.getMonthValuesForMask(topSkuIds, this.limit)
    let mask = null;
    for(mask of rawMaskData) {
      allMasksData[mask._id] = orderBy(mask.skuVal, 'sku', 'asc')
      maskIndexes[mask._id] = 0
      availableMasks.push(mask._id)
    }

    const result = []

    for(let i = 0; i < topSkuIds.length; i++) {
      const row = {}
      availableMasks.forEach(mask => {
        const { value, index, position } = getProperValueBasedOnSortedCollections(allMasksData[mask], maskIndexes[mask], topSkuIds, i, 'sku')
        row[`${mask}Value`] = value;
        row[`${mask}Position`] = position + 1;
        maskIndexes[mask] = index;
      })
      row.productName = this.orderedTopGlobalMonthValues[i].productName
      row.sku = this.orderedTopGlobalMonthValues[i]._id
      result.push(row)
    }

    if(availableMasks.indexOf('outhorn_store_pl'>= 0)) {
      this.topProductsPerMask = orderBy(result, 'outhorn_store_plPosition', 'asc')
    } else {
      this.topProductsPerMask = result;
    }

    this.columnsPerMask = generateColumnsForMergedCollectionPLBased(availableMasks, 'Wartość', this.limit, currencyFormatter.format);
    this.loadingTopProductsPerMask = false;
  }

  async getMonthValuesForMask(topSkuId, limit) {
    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      skuIds: topSkuId.toString(),
      limit,
      skuKey: "$objects_id"
    }

    const { data: topByMask } = await API.get(`${API_ROUTES.TOP_PRODUCTS_BY_MASK}?${qs.stringify(monthQuery)}`);
    return topByMask
  }


  @action fetchMasks = async () => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.wishlistSumPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: 'store'
    }

    const { data } = await API.get(`${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`);
    this.availableMasks = data;
    this.loadingAvailableMasks = false;
  }

  @action fetchTopProductsCategories = async () => {
    this.loadingTopCategories = true;
    this.topCategories = [];

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$objects_id',
      aggregateKey: '$value',
      limit: this.limit
    }

    let { data: categories } = await API.get(`${API_ROUTES.TOP_PRODUCTS_CATEGORIES}?${qs.stringify(monthQuery)}`);

    this.topCategories = categories;
    this.loadingTopCategories = false;
  }


  @action fetchTopProductsCategoriesPerMask = async () => {
    this.loadingTopCategoriesByMask = true;
    this.topCategoriesByMask = [];

    const monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      limit: this.limit,
      skuKey: "$objects_id"
    }

    const { data: topByMask } = await API.get(`${API_ROUTES.TOP_CATEGORIES_BY_MASK}?${qs.stringify(monthQuery)}`);

    this.availableMasksForCategories = topByMask.map(top => top._id)
    this.topCategoriesByMask = topByMask;
    this.loadingTopCategoriesByMask = false;
  }

  @action setChosenCategoriesMasks = (options) => {
    this.chosenCategoriesMasks = options
  }

  @action setChosenProductsMasks = (options) => {
    this.chosenProductsMasks = options
  }

  @action fetchTopProductsPerMask = async (fromDate = moment().subtract(1, 'month').toISOString(), toDate = moment().toISOString()) => {
    this.loadingTopProductsPerMaskDropdown = true;
    this.topProductsForMask = [];

    let monthQuery = {
      statId: config.statIds.productsValuesPerMaskPerSku,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      limit: this.limit,
      skuKey: "$objects_id"
    }

    const { data: topByMaskValue } = await API.get(`${API_ROUTES.TOP_PRODUCTS_BY_MASK}?${qs.stringify(monthQuery)}`);
    monthQuery.statId = config.statIds.productsCountPerMaskPerSku;

    let item = null;

    for(item of topByMaskValue) {
      const mask = item._id;
      const params = { store: mask }
      let { data: { sum: monthAmountForMask } } = await aggregationsStore.fetchStatisticsSum(monthQuery.timeFrom, monthQuery.timeTo, monthQuery.step, monthQuery.statId, monthQuery.skuKey, { params })
      let obj = {}

      monthAmountForMask.forEach((data) => {
        obj[data._id] = data.sum
      })
      item.skuVal.forEach((valueObj) => {
        valueObj.amount = obj[valueObj.sku] || '-'
        valueObj.position += 1
      })
    }

    this.topProductsForMask = topByMaskValue;

    this.availableMasksForProducts = topByMaskValue.map(({ _id }) => _id)
    this.loadingTopProductsPerMaskDropdown = false;
  }
}


export default new ProductsStore();
