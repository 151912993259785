import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Title } from "../../components/helpers/chartHelpers";
import TimeframeShortcuts from "../../components/TimeframeShortcuts/TimeframeShortcuts";
import CustomerJourneyFunnelChart from "./Components/CustomerJourneyFunnelChart";


class CustomerJourneyPageContainer extends Component {

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Customer journey</Title>
          </GridItem>
          <GridItem xs={12}>
            <CustomerJourneyFunnelChart />
          </GridItem>
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '10px 10px' }} />
          <GridItem xs={2}>
            <TimeframeShortcuts />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default CustomerJourneyPageContainer;

