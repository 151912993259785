import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const StyledTextField = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: '#808080',
    },
    '& label.Mui-focused': {
      color: '#808080',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#808080',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#454545',
      },
      '&:hover fieldset': {
        borderColor: '#808080',
      },
      '&.Mui-focused fieldset': {
        color: "white",
        borderColor: '#808080',
      },
    },
  },
})(TextField);