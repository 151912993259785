import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Loader({ size, ...props }) {
  return (
    <Flex width={ 1 } justifyContent="center" py={ 40 } { ...props }>
      <CircularProgress size={ size }/>
    </Flex>
  );
}

Loader.propTypes = {
  size: PropTypes.number
};

Loader.defaultProps = {
  size: 40
};
