import axios from "axios";
// import authStore from "../stores/authStore";

const TOKEN = process.env.REACT_APP_TOKEN

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // if(error.response.data.error === "Invalid token") {
    //   return authStore.logout()
    // }
    return Promise.reject(error.response);
  }
);

axiosInstance.interceptors.request.use(async function (config) {
  // const token = await authStore.getToken();
  config.headers.Authorization =  `Bearer ${TOKEN}`;

  return config;
});

export default axiosInstance;
