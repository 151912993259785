import { action, observable } from 'mobx';
import config from '../config';
import moment from 'moment';
import 'moment/locale/pl';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import qs from 'qs';
import aggregationsStore from './aggregationsStore';
import { orderBy, sortBy } from 'lodash';
import {
  getProperValueBasedOnSortedCollections
} from '../components/utils/collectionMergingUtils';

export class BannersStore {

  @observable loadingTopBannersGlobal = false;
  @observable topBannersGlobal = [];
  @observable topBannersIds = false;

  @observable availableMasks = [];
  @observable loadingAvailableMasks = true;

  @observable availableMasksDateRange = [];
  @observable loadingAvailableMasksDateRange = false;

  @observable chosenMasks = [];

  @observable loadingTopBannersPerMaskCounter = 0;
  @observable topBannersPerMask = [];

  @observable chosenDateRangeMasks = [];

  @observable loadingTopBannersPerMaskDateRangeCounter = 0;
  @observable topBannersPerMaskDateRange = [];

  limit = 100;

  @action setChosenMasks = (options) => {
    this.chosenMasks = options;
  };

  @action setChosenDateRangeMasks = (options) => {
    this.chosenDateRangeMasks = options;
  };

  @action
  fetchMasks = async () => {
    this.loadingAvailableMasks = true;
    this.availableMasks = [];

    const currentMonthQuery = {
      statId: config.statIds.bannerCountPerBannerPerMask,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: 'store'
    };

    const { data } = await API.get(`${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`);

    this.availableMasks = data;
    this.loadingAvailableMasks = false;
  };

  @action
  fetchMasksForDateRange = async (fromDate, toDate) => {
    this.loadingAvailableMasksDateRange = true;
    this.availableMasksDateRange = [];

    const currentMonthQuery = {
      statId: config.statIds.bannerCountPerBannerPerMask,
      step: '1D',
      timeFrom: moment(fromDate).toISOString(),
      timeTo: moment(toDate).toISOString(),
      key: 'store'
    };

    const { data } = await API.get(`${API_ROUTES.STATISTIC_DISTINCT_KEY}?${qs.stringify(currentMonthQuery)}`);

    this.availableMasksDateRange = data;
    this.loadingAvailableMasksDateRange = false;
  };

  @action
  fetchBannersTop = async (mask) => {
    let statId = null;
    if (mask) {
      if (this.topBannersPerMask[mask] && this.topBannersPerMask[mask].length !== 0) {
        return;
      } else {
        statId = config.statIds.bannerCountPerBannerPerMask;
        this.loadingTopBannersPerMaskCounter += 1;
        this.topBannersPerMask[mask] = [];
      }
    } else {
      statId = config.statIds.bannerCountPerBanner;
      this.loadingTopBannersGlobal = true;
      this.topBannersGlobal = [];
    }

    const monthQuery = {
      statId: statId,
      step: '1D',
      timeFrom: moment().startOf('month').toISOString(),
      timeTo: moment().toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
    };

    if (mask) {
      monthQuery.store = mask;
    }

    let { data: month } = await API.get(`${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(monthQuery)}`);
    month.forEach((item, index) => item.position = index + 1);

    let topBannerIds = month.map((item) => item._id);
    topBannerIds = sortBy(topBannerIds);
    this.topBannerIds = topBannerIds;

    const todayQuery = {
      step: '1H',
      timeFrom: moment().startOf('day').toISOString(),
      timeTo: moment().toISOString(),
      key: '$object_name',
    };

    const yesterdayQuery = {
      step: '1H',
      timeFrom: moment().subtract(1, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$object_name',
    };

    const weekQuery = {
      step: '1H',
      timeFrom: moment().subtract(7, 'days').startOf('day').toISOString(),
      timeTo: moment().startOf('day').toISOString(),
      key: '$object_name',
    };

    const params = {
      object_name: topBannerIds.toString()
    };

    let { data: { sum: todayValue } } = await aggregationsStore.fetchStatisticsSum(todayQuery.timeFrom, todayQuery.timeTo, todayQuery.step, statId, todayQuery.key, { params });
    let { data: { sum: yesterdayValue } } = await aggregationsStore.fetchStatisticsSum(yesterdayQuery.timeFrom, yesterdayQuery.timeTo, yesterdayQuery.step, statId, yesterdayQuery.key, { params });
    let { data: { sum: weekValue } } = await aggregationsStore.fetchStatisticsSum(weekQuery.timeFrom, weekQuery.timeTo, weekQuery.step, statId, weekQuery.key, { params });


    const monthValue = orderBy(month, '_id', 'asc');
    this.orderedTopGlobalMonthValues = monthValue;
    const collectionsToMerge = {
      todayValue,
      yesterdayValue,
      weekValue,
      monthValue
    };

    const indexes = {
      todayValue: 0,
      yesterdayValue: 0,
      weekValue: 0,
      monthValue: 0
    };

    const result = [];
    for (let i = 0; i < topBannerIds.length; i++) {
      const row = {};

      Object.keys(collectionsToMerge).forEach(collectionName => {
        const {
          value,
          index
        } = getProperValueBasedOnSortedCollections(collectionsToMerge[collectionName], indexes[collectionName], topBannerIds, i, '_id');
        row[collectionName] = value;
        indexes[collectionName] = index;
      });

      const title = collectionsToMerge.monthValue[indexes.monthValue - 1].title;

      row.position = collectionsToMerge.monthValue[indexes.monthValue - 1].position;
      row.analyticsId = collectionsToMerge.monthValue[indexes.monthValue - 1]._id;
      row.image = collectionsToMerge.monthValue[indexes.monthValue - 1].image;
      row.mainImage = collectionsToMerge.monthValue[indexes.monthValue - 1].mainImage;
      row.store = collectionsToMerge.monthValue[indexes.monthValue - 1].store || '-';
      row.title = (title && title.trim() !== '') ? title : '-';
      result.push(row);
    }

    if (mask) {
      this.topBannersPerMask[mask] = orderBy(result, 'monthValue', 'desc');
      this.loadingTopBannersPerMaskCounter -= 1;
    } else {
      this.topBannersGlobal = orderBy(result, 'monthValue', 'desc');
      this.loadingTopBannersGlobal = false;
    }

  };

  @action
  fetchBannersTopDateRange = async (mask, dateFrom, dateTo) => {
    const statId = config.statIds.bannerCountPerBannerPerMask;
    this.loadingTopBannersPerMaskDateRangeCounter += 1;
    this.topBannersPerMaskDateRange[mask] = [];

    const monthQuery = {
      statId: statId,
      step: '1D',
      timeFrom: moment(dateFrom).startOf('month').toISOString(),
      timeTo: moment(dateTo).toISOString(),
      key: '$object_name',
      aggregateKey: '$value',
      limit: this.limit,
      store: mask
    };

    let { data: month } = await API.get(`${API_ROUTES.STATISTICS_TOP_BANNERS}?${qs.stringify(monthQuery)}`);
    month.forEach((item, index) => item.position = index + 1);
    this.topBannersPerMaskDateRange[mask] = orderBy(month, 'value', 'desc');
    this.loadingTopBannersPerMaskDateRangeCounter -= 1;
  };

}


export default new BannersStore();
