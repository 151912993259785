import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import CustomLineChart from "../../../components/CustomLineChart/CustomLineChart";
import { currencyFormatter } from "../../../components/helpers/utils";
import { StyledInput } from '../../../components/helpers/chartHelpers';
import PolishDatePicker from '../../../components/PolishDatePicker/PolishDatePicker';
import styled from 'styled-components';

const DayPickerContainer = styled.div`
  width: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
`

const colors = ["#272727", "#8e0000", '#194f82']
const keys = ['today', 'yesterday', 'week'];
const keysTranslation = ['wybrany dzień', 'poprzedni dzień', 'w poprzednim tygodniu']
const xAxis = 'hour'

const PurchaseLineChart = ({ purchaseStore }) => {
  const { loadingPurchaseHourlyData, purchaseHourly, fetchPurchaseHourly, fetchTopBasicInfo, setHourlyDate, hourlyDate } = purchaseStore;

  useEffect(() => {
    fetchPurchaseHourly(hourlyDate)
    fetchTopBasicInfo(hourlyDate);

  }, [hourlyDate]);

  return(
    <>
      <CustomLineChart
        loadingData={loadingPurchaseHourlyData}
        data={purchaseHourly}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
        valueFormatter={(value) => currencyFormatter.format(value)}
      />
      <DayPickerContainer>
        <div style={{ marginTop: 5 }}>Dzień:</div>
        <PolishDatePicker
          showTimeSelect={false}
          dateFormat="dd/MM/yyyy"
          selected={hourlyDate}
          onChange={setHourlyDate}
          customInput={<StyledInput/>}
        />
      </DayPickerContainer>
    </>);
};

export default inject('purchaseStore')(observer(PurchaseLineChart));

PurchaseLineChart.propTypes = {
  purchaseStore: PropTypes.shape({
    loadingPurchaseHourlyData: PropTypes.bool,
    purchaseHourly: PropTypes.array,
    fetchPurchaseHourly: PropTypes.func,
    fetchTopBasicInfo: PropTypes.func,
    setHourlyDate: PropTypes.func,
    hourlyDate: PropTypes.any
  })
}