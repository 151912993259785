import React from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Button from "@material-ui/core/Button";

function TimeframeShortcuts({ aggregationsStore }) {
  return (
    <div>
      <h3 style={{ margin: '35px 0px 30px 10px' }}>Skróty</h3>
      {aggregationsStore.shortcuts.map((shortcut) => {
        return <Button onClick={() => aggregationsStore.setShortcuts(shortcut.label) } key={shortcut.label}>{shortcut.label}</Button>
      })}
    </div>
  )
}
export default inject('aggregationsStore')(observer(TimeframeShortcuts));

TimeframeShortcuts.propTypes = {
  aggregationsStore: PropTypes.shape({
    shortcuts: PropTypes.array,
    setShortcuts: PropTypes.func
  })
}

