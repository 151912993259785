import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import CustomLineChart from "../../../components/CustomLineChart/CustomLineChart";
import moment from "moment";
import {
  BaseOption,
  customDropdownStyles, FilterBox,
  steps,
  StyledInput
} from '../../../components/helpers/chartHelpers';
import { percentFormatter } from "../../../components/helpers/utils";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";
import Select from 'react-select';
import { Typography } from '@material-ui/core';

const colors = ["#272727"]
const keys = ['percent'];
const keysTranslation = ['Stosunek otwartych notyfikacji do aktywnych użytkowników']
const xAxis = 'fromTs'

const Tooltip = ({ payload, value }) => <>
  {percentFormatter.format(value/100)}
  <div>Liczba otwartych notyfikacji : {payload.pushCount}</div>
  <div>Liczba aktywnych użytkowników : {payload.usersCount}</div>
</>

Tooltip.propTypes = {
  payload: PropTypes.any,
  value: PropTypes.number
}

const PushLineChart = inject("usersStore")(observer(({ usersStore }) => {
  const { loadingPushDate, activePushData, fetchPushData } = usersStore;
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [step, setStep] = useState('1D');

  useEffect(() => {
    if (startDate && endDate) {
      fetchPushData(startDate, endDate, step)
    }
  }, [startDate, endDate, step])
  return (
    <>
      <div style={{ width: '100%' }}>
        <Typography color='textSecondary' align='center' gutterBottom>
          Procent otwartych notyfikacji
        </Typography>
      </div>
      <CustomLineChart
        yTickFormatter={(value) => `${value}%`}
        loadingData={loadingPushDate}
        data={activePushData}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
        valueFormatter={(value, name, props) => <Tooltip key={123} payload={props.payload} value={value} />}
      />
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: 30 }}>
        <div style={{ width: '70%' }}>
          <FilterBox>
            <FilterBox>
              Od:
              <PolishDatePicker
                showTimeSelect
                dateFormat='dd/MM/yyyy hh:mm'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<StyledInput />}
              />
            </FilterBox>
            <FilterBox>
              Do:
              <PolishDatePicker
                showTimeSelect
                dateFormat='dd/MM/yyyy h:mm aa'
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<StyledInput />}
              />
            </FilterBox>
            <FilterBox>
              Przedział:
              <Select
                options={steps}
                value={steps.filter((option) => option.value === step)}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                styles={customDropdownStyles}
                components={{ Option: BaseOption }}
                onChange={(option) => setStep(option.value)}
              />
            </FilterBox>
          </FilterBox>
        </div>
      </div>
    </>);
}));

export default PushLineChart;

PushLineChart.propTypes = {
  usersStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}