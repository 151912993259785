export default {
  statIds: {
    usersCount: "60ab8f2c141167afb7fd243c",
    usersUniquePageViewCount: "6102833fe821372b5def2fa0",
    usersByMediumByPageViewCount: "60ab8fa1141167afb7fd4143",
    usersByGenderByPageViewCount: "60ab8fae141167afb7fd43ad",
    activePushCount: "60ab8f10141167afb7fd1cab",
    purchasesCount: "60ab8f46141167afb7fd2bf0",
    purchasesWithVoucherCount: "60ab8f82141167afb7fd3aa6",
    bannerCount: "61b746e93b303542e985aaec",
    bannerCountPerBanner: "61b746e93b303542e985aaf8",
    bannerCountPerBannerPerMask: "61b746e93b303542e985ab02",
    purchasesTotalPriceSum: "60d3217ad236b76e1c29db8c",
    purchasesTotalPriceSumPerMask: "60d3217ad236b76e1c29db97",
    purchasesCountPerMask: "60d3217ad236b76e1c29dba2",
    purchasesAvgTotalPricePerMask: "60d3217ad236b76e1c29dbad",
    discountsSum: "60e44c9fc6ac832fdf451604",
    discountsSumPerMask: "60e44c9fc6ac832fdf45160f",
    productsCountPerMaskPerSku: "60eedccaaa2dd4fccd605c97",
    productsValuesPerMaskPerSku: "60eedccaaa2dd4fccd605ca2",
    wishlistSum: "60e5ad0966bd536377b3ef66",
    wishlistSumPerMask: "60e5ad0966bd536377b3ef71",
    churnCount: "611e79754d8e60a75b2ff26b",
    monthDevicesDistinct: "611f86784d8e60a75b61cfd2",
    purchasesPerPaymentType: "61ee8f5cbde689ed7677a570",
    purchasesPerPaymentProvider: "61ee8f5cbde689ed7677a579",
    purchasesPerDeliveryType: "61ee8f5cbde689ed7677a583",
    purchasesPerMaskPaymentTypePaymentProvider: "622f08b3db50261c791113df",
    activePPGCount: "6321ed0a95b103fc22430f08",
  }
};
