import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Subtitle, Title } from "../../components/helpers/chartHelpers";
import TopWishlistProductsPerMaskTable from "./Components/TopWishlistProductsPerMaskTable";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import TopWishlistProductsGlobalTable from "./Components/TopWishlistProductsGlobalTable";

@observer
@inject('wishlistStore')
class WishlistsPageContainer extends Component {

  async componentDidMount() {

  }

  render() {
    return (
        <div>
          <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
            <GridItem xs={12}>
              <Title color="#212121">Ulubione produkty</Title>
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">Top 100 ulubionych produktów w bieżącym miesiącu (globalnie)</Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <TopWishlistProductsGlobalTable />
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">Top 100 ulubionych produktów w bieżącym miesiącu (dla maski)</Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <TopWishlistProductsPerMaskTable />
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default WishlistsPageContainer;

WishlistsPageContainer.propTypes = {
  wishlistStore: PropTypes.shape({

  })
}

