import React, { useState, useEffect }  from 'react'
import UsersBarChart from './UsersBarChart'
import { mediums } from '../../../components/helpers/chartHelpers'

const CURRENT_MEDIUMS = {
  iOS: 'iOS',
  Android: 'Android',
}

const UserBarByMediumCharts = () => {
    const getMedium = (medium) => mediums.find((m) => m.label === medium)
  const [{ iOS, android }, setMedium] = useState({
      iOS: getMedium(CURRENT_MEDIUMS.iOS),
      android: getMedium(CURRENT_MEDIUMS.Android),
    })

  useEffect(() => {
    setMedium(() => ({
      iOS: getMedium(CURRENT_MEDIUMS.iOS),
      android: getMedium(CURRENT_MEDIUMS.Android),
    }))
  }, [])

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ flexBasis: '50%' }}>
        <UsersBarChart medium={iOS} />
      </div>
      <div style={{ flexBasis: '50%' }}>
        <UsersBarChart medium={android} />
      </div>
    </div>
  )
}

export default UserBarByMediumCharts
