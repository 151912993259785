import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { SubtitleWithoutBorder, Title } from '../../components/helpers/chartHelpers';
import UsersBarChart from "../UsersPage/Components/UsersBarChart";
import GenderPieChart from "./Components/GenderPieChart";
import PaymentCharts from './Components/PaymentCharts';
import PaymentLineChart from './Components/PaymentLineChart';
import PersonaShortcuts from './Components/PersonaShortcuts';

class PersonPageContainer extends Component {

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Persona</Title>
          </GridItem>
          <GridItem xs={5}>
            <GenderPieChart />
          </GridItem>
          <GridItem xs={7}>
            <UsersBarChart />
          </GridItem>
          <GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '10px 10px' }} />
          <GridItem xs={2} style={{ borderRight: "1px solid #212121" }}>
            <PersonaShortcuts />
          </GridItem>
          <GridItem xs={10}>
            <SubtitleWithoutBorder>Płatności</SubtitleWithoutBorder>
            <PaymentCharts />
          </GridItem>
          <GridItem xs={2} style={{ borderRight: "1px solid #212121" }} />
          <GridItem xs={10} style={{ borderTop: "1px solid #212121" }} >
            <PaymentLineChart />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default PersonPageContainer;

