import React  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
import { currencyFormatter } from "../../../components/helpers/utils";

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'category', label: 'Kategoria', minWidth: 100 },
  { id: 'value', label: 'Wartość', minWidth: 100, format: currencyFormatter.format },
];

function TopCategoriesGlobalTable({ productsStore }) {

  const { loadingTopCategories, topCategories } = productsStore;

  return (
    <TableWithJson
      columns={columns}
      data={topCategories}
      loadingData={loadingTopCategories}
      withPagination={false}
      showJson={false}
    />
  );
}

export default inject('productsStore')(observer(TopCategoriesGlobalTable));

TopCategoriesGlobalTable.propTypes = {
  productsStore: PropTypes.shape({
    topCategories: PropTypes.array,
    loadingTopCategories: PropTypes.bool
  })
};