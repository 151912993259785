import React  from "react";
import { inject, observer } from "mobx-react";
import CustomLineChart from "../../../components/CustomLineChart/CustomLineChart";

const colors = ["#272727", "#8e0000", '#194f82']
const keys = ['today', 'yesterday', 'week'];
const keysTranslation = ['dzis', 'wczoraj', 'WoW']
const xAxis = 'hour'

const DiscountLineChart = inject("discountStore")(observer(({ discountStore }) => {
  const { loadingDiscountsHourlyData, discountsHourly } = discountStore;

  return(
      <CustomLineChart
        loadingData={loadingDiscountsHourlyData}
        data={discountsHourly}
        xAxisKey={xAxis}
        dataKeys={keys}
        keysTranslation={keysTranslation}
        keysColors={colors}
    />);
}));

export default DiscountLineChart;