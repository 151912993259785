import React from 'react';

import Select from 'react-select';
import * as PropTypes from "prop-types";

function CustomSelect ({ loading, setValue, options, value, placeholder }) {
  return (
  <div style={{ display: 'flex' }}>
    <div style={{ width: '100%', minWidth: 270, marginBottom: 20, marginTop: 20 }}>
      <Select
        isClearable
        isLoading={loading}
        value={[value]}
        placeholder={placeholder}
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
        name="colors"
        options={options}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        onChange={(option) => {
          setValue(option)
        }}
      />
    </div>
  </div>
    )
}

export default CustomSelect;

CustomSelect.propTypes = {
  loading: PropTypes.bool,
  setValue: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};