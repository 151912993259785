import React from "react";
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from "react-router-dom";
import * as PropTypes from "prop-types";

const PrivateRoute = inject("authStore")(observer(({ authStore, component: RouteComponent, ...rest }) => {
  return (
     <Route
      {...rest}
      render={routeProps =>
        authStore.isAuthenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/login"}/>
        )
      }
    />
  );
}));

export default PrivateRoute

PrivateRoute.propTypes = {
  component: PropTypes.object,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool
  })
}