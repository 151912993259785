import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { inject, observer } from "mobx-react";
import {
  showLoaderOrPlaceHolder, Filters, FilterBox, StyledInput
} from "../../../components/helpers/chartHelpers";
import moment from "moment";
import * as PropTypes from "prop-types";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";
import { percentFormatter } from '../../../components/helpers/utils';


const COLORS = ['#0088FE', '#00C49F'];

const GenderPieChart = inject("personaStore")(observer(({ personaStore }) => {
  const { shortcutFrom: from, shortcutTo: to } = personaStore;
  const timeFrom = from? moment(from).toDate() : moment().subtract(1, 'day').startOf('day').toDate();
  const timeTo = to? moment(to).toDate() : moment().endOf('day').toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);
  const { genderPieChartData, loadingGenderPieChartData, fetchGenderPieChartData } = personaStore;

  useEffect(() => {
    fetchGenderPieChartData(startDate.toISOString(), endDate.toISOString());
  }, [startDate, endDate]);

  useEffect(() => {
    if(from && to) {
      setStartDate(from);
      setEndDate(to);
    }
  }, [from, to])

  const tooltipFormatter = (value, name, props) => {
    const percent = percentFormatter.format(props.payload.payload.percent)
    const tooltipValue = `${value} (${percent})`
    return [tooltipValue, name];
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        {loadingGenderPieChartData || genderPieChartData.length === 0 ? (
          showLoaderOrPlaceHolder(loadingGenderPieChartData)
        ) : (
          <PieChart width={400} height={400}>
            <Tooltip formatter={tooltipFormatter} />
            <Legend wrapperStyle={{ paddingBottom: '10px' }}/>
            <Pie
              data={genderPieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              nameKey="label"
            >
              {genderPieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index%COLORS.length]}/>
              ))}
            </Pie>
          </PieChart>
        )}
      </ResponsiveContainer>
      <Filters>
        <FilterBox>
          Od:
          <PolishDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<StyledInput />}
          />
        </FilterBox>
        <FilterBox>
          Do:
          <PolishDatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            customInput={<StyledInput />}
          />
        </FilterBox>
      </Filters>
    </>
  );
}));

export default GenderPieChart;

GenderPieChart.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
  personaStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}