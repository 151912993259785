import React, { useRef, useEffect } from "react";
import D3Funnel from "d3-funnel";
import * as PropTypes from "prop-types";

export default function Funnel({ data }) {
  const chartRef = useRef(null);

  var options = {
    label: {
      format: "{l}\n{f}",
      fill: "#000000"
    },
    block: {
      minHeight: 40,
      dynamicHeight: true,
      fill: {
        type: 'gradient',
      },
      highlight: true
    },
  };

  useEffect(() => {
    const chart = new D3Funnel(chartRef.current);
    chart.draw(data, options);
  }, []);
  return <div style={{ margin: 'auto', maxWidth: '1100px', overflowX: "hidden" }} className="Funnel" ref={chartRef}></div>;
}

Funnel.propTypes = {
  data: PropTypes.array,
}