import React  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import BasicCard from "../../../components/BasicCard/BasicCard";

function TopBasicInfo({ purchaseStore }) {
    const { monthSum, thisWeekSum, lastWeekSum } = purchaseStore;

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BasicCard label="Przychody w wybranym tygodniu" value={thisWeekSum} />
            <BasicCard label="Przychody w poprzednim tygodniu" value={lastWeekSum} />
            <BasicCard label="Przychody od początku wybranego miesiąca" value={monthSum} />
        </div>
    );
}

export default inject('purchaseStore')(observer(TopBasicInfo));

TopBasicInfo.propTypes = {
    purchaseStore: PropTypes.shape({
        thisWeekSum: PropTypes.array,
        monthSum: PropTypes.number,
        lastWeekSum: PropTypes.bool
    })
};