import React  from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';

import PropTypes from "prop-types";
import { showLoaderOrPlaceHolder } from "../../../components/helpers/chartHelpers";


const VerticalBarChart = ({
  loadingData, chartData, keyNames, dataKey, keyTranslations, labelFormatter, keyColors
}) => {
  const id = `chart-vert-${Math.floor(Math.random() * 1000)}`;

  if (chartData.length === 0 || loadingData) {
    return (
        <ResponsiveContainer id={id} width="100%" height={550}>
          {showLoaderOrPlaceHolder(loadingData, false)}
        </ResponsiveContainer>
        )
  }

  return (
    <ResponsiveContainer id={id} width="100%" height={550}>
      <BarChart
        data={chartData}
        margin={{
          top: 5, right: 50, bottom: 5,
        }}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis type="number" stroke="#aaaaaa" tickFormatter={labelFormatter}/>
        <YAxis type="category" dataKey={dataKey} stroke="#aaaaaa" width={150} />
        <Tooltip formatter={labelFormatter} />
        {keyNames.map((key, index) => (
          <Bar key={`${key}-${index}`} dataKey={key} name={keyTranslations[index]} fill={keyColors[index]} />
        )) }
        <Legend wrapperStyle={{ paddingTop: '10px', paddingBottom: '30px' }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarChart;

VerticalBarChart.propTypes = {
  loadingData: PropTypes.bool,
  chartData: PropTypes.array,
  keyNames: PropTypes.array,
  dataKey: PropTypes.string,
  title: PropTypes.string,
  keyTranslations: PropTypes.array,
  labelFormatter: PropTypes.func,
  keyColors: PropTypes.array
}
