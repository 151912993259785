import { ResponsiveContainer } from "recharts";
import {
  BaseOption,
  customDropdownStyles,
  FilterBox, Filters,
  showLoaderOrPlaceHolder, StyledInput, mediums, showLineChart, formatDate
} from "../../../components/helpers/chartHelpers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select"
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";


const UsersByMediumCharts = inject("usersStore")(observer(({ usersStore }) => {
  const { shortcutFrom: from, shortcutTo: to } = usersStore;
  const timeFrom = from? moment(from).toDate() : moment().subtract(1, 'day').toDate();
  const timeTo = to? moment(to).toDate() : moment().toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);
  const [medium, setMedium] = useState();
  const { loadingUsersByMediumBarChartData, usersByMediumBarChartData, fetchUsersByMediumBarChartData } = usersStore;

  const mediumsWithAll = mediums.concat([{ value: 'all', label: 'Wszystkie' }])

  useEffect(() => {
    if(medium) {
      fetchUsersByMediumBarChartData(startDate.toISOString(), endDate.toISOString(), '1D', medium);
    }
  }, [startDate, endDate, medium]);

  useEffect(() => {
    if(from && to) {
      setStartDate(from);
      setEndDate(to);
    }
  }, [from, to])

  return <>
    <Filters>
      <FilterBox>
        Od:
        <PolishDatePicker
          showTimeSelect
          dateFormat="dd/MM/yyyy hh:mm"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<StyledInput width="400px" />}
        />
      </FilterBox>
      <FilterBox>
        Do:
        <PolishDatePicker
          showTimeSelect
          dateFormat="dd/MM/yyyy h:mm aa"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<StyledInput width="400px" />}
        />
      </FilterBox>
      <FilterBox>
        Medium:
        <Select
          placeholder="Wybierz..."
          options={mediumsWithAll}
          value={mediumsWithAll.filter((option) => option.value === medium)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          styles={customDropdownStyles}
          components={{ Option: BaseOption }}
          onChange={(option) => setMedium(option.value)}
        />
      </FilterBox>
    </Filters>
    <ResponsiveContainer
      width="100%"
      height={300}
    >
      {loadingUsersByMediumBarChartData || usersByMediumBarChartData.length === 0 ? (
        showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
      ) : showLineChart(usersByMediumBarChartData, 'value', 'fromTs', true, 'value', "#212121", formatDate)}
    </ResponsiveContainer>
    <ResponsiveContainer
      width="100%"
      height={300}
    >
      {loadingUsersByMediumBarChartData || usersByMediumBarChartData.length === 0 ? (
        showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
      ) : showLineChart(usersByMediumBarChartData, 'rsi', 'fromTs', true, 'RSI', '#820BBB', formatDate, true)}
    </ResponsiveContainer>
    <ResponsiveContainer
      width="100%"
      height={300}
    >
      {loadingUsersByMediumBarChartData || usersByMediumBarChartData.length === 0 ? (
        showLoaderOrPlaceHolder(loadingUsersByMediumBarChartData)
      ) : showLineChart(usersByMediumBarChartData, 'macd', 'fromTs', true, 'MACD', '#FB9101', formatDate, true)}
    </ResponsiveContainer>
  </>;
}));

export default UsersByMediumCharts;

UsersByMediumCharts.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
  aggregationsStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}