import React  from 'react';
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TableWithJson from "../../../components/TableWithJson/TableWithJson";
import { currencyFormatter } from "../../../components/helpers/utils";
import { showLoaderOrPlaceHolder, Subtitle } from "../../../components/helpers/chartHelpers";

const columns = [
  { id: 'position', label: 'Pozycja', minWidth: 100 },
  { id: 'sku', label: 'SKU', minWidth: 100 },
  { id: 'productName', label: 'Kategoria', minWidth: 100 },
  { id: 'amount', label: 'Ilość', minWidth: 100 },
  { id: 'value', label: 'Wartość', minWidth: 100, format: currencyFormatter.format },
];

function TopProductsMaskTable({ productsStore }) {

  const { loadingTopProductsPerMaskDropdown, topProductsForMask, chosenProductsMasks } = productsStore;

  const products = topProductsForMask.filter(mask => chosenProductsMasks.indexOf(mask._id) >= 0)

  return products.map(({ _id, skuVal }, index) => (
    <div key={index}>
      <Subtitle>{_id.toUpperCase()}</Subtitle>
      {(skuVal && skuVal.length === 0) ?
        showLoaderOrPlaceHolder(loadingTopProductsPerMaskDropdown, false)
        : (<TableWithJson
          columns={columns}
          data={skuVal}
          loadingData={loadingTopProductsPerMaskDropdown}
          withPagination={false}
          showJson={false}
        />)
      }
    </div>
  ))
}

export default inject('productsStore')(observer(TopProductsMaskTable));

TopProductsMaskTable.propTypes = {
  productsStore: PropTypes.shape({
    topProductsForMask: PropTypes.array,
    chosenProductsMasks: PropTypes.array,
    loadingTopProductsPerMaskDropdown: PropTypes.bool
  })
};