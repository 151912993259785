import { ResponsiveContainer } from "recharts";
import {
  BaseOption,
  steps, customDropdownStyles,
  FilterBox, Filters,
  showBarChart,
  showLoaderOrPlaceHolder, StyledInput
} from "../../../components/helpers/chartHelpers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select"
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import PolishDatePicker from "../../../components/PolishDatePicker/PolishDatePicker";


const BannersBarChart = inject("aggregationsStore")(observer(({ aggregationsStore }) => {
  const { shortcutFrom: from, shortcutTo: to } = aggregationsStore;
  const timeFrom = from? moment(from).toDate() : moment().subtract(1, 'day').toDate();
  const timeTo = to? moment(to).toDate() : moment().toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);
  const [step, setStep] = useState('5m');
  const { loadingBannerBarChartData, bannerBarChartData, fetchBannerBarChartData } = aggregationsStore;

  useEffect(() => {
    fetchBannerBarChartData(startDate.toISOString(), endDate.toISOString(), step);
  }, [startDate, endDate, step]);

  useEffect(() => {
    if(from && to) {
      setStartDate(from);
      setEndDate(to);
    }
  }, [from, to])

  return <>
    <ResponsiveContainer
      width="100%"
      height={300}
    >
      {loadingBannerBarChartData || bannerBarChartData.length === 0 ? (
        showLoaderOrPlaceHolder(loadingBannerBarChartData)
      ) : showBarChart(bannerBarChartData, 'value', 'fromTs', true, null)}
    </ResponsiveContainer>
    <Filters>
      <FilterBox>
        Od:
        <PolishDatePicker
          showTimeSelect
          dateFormat="dd/MM/yyyy hh:mm"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<StyledInput width="400px" />}
        />
      </FilterBox>
      <FilterBox>
        Przedział:
        <Select
          options={steps}
          value={steps.filter((option) => option.value === step)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          styles={customDropdownStyles}
          components={{ Option: BaseOption }}
          onChange={(option) => setStep(option.value)}
        />
      </FilterBox>
      <FilterBox>
        Do:
        <PolishDatePicker
          showTimeSelect
          dateFormat="dd/MM/yyyy h:mm aa"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<StyledInput width="400px" />}
        />
      </FilterBox>
    </Filters>
  </>;
}));

export default BannersBarChart;

BannersBarChart.propTypes = {
  from: PropTypes.any,
  to: PropTypes.any,
  aggregationsStore: PropTypes.shape({
    shortcutFrom: PropTypes.any,
    shortcutTo: PropTypes.any
  })
}