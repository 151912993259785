import { FilterBox, StyledInput } from "../helpers/chartHelpers";
import PolishDatePicker from "../PolishDatePicker/PolishDatePicker";
import * as PropTypes from "prop-types";
import React from "react";

export default function DateRangePicker({ startDate, onStartDateChange, endDate, onEndDateChange, showTime = true }) {
  return <>
    <FilterBox>
      Od:
      <PolishDatePicker
        showTimeSelect={showTime}
        dateFormat={showTime ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        selected={startDate}
        onChange={onStartDateChange}
        customInput={<StyledInput/>}
      />
    </FilterBox>
    <FilterBox>
      Do:
      <PolishDatePicker
        showTimeSelect={showTime}
        dateFormat={showTime ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        selected={endDate}
        onChange={onEndDateChange}
        customInput={<StyledInput/>}
      />
    </FilterBox>
  </>;
}

DateRangePicker.propTypes = {
  startDate: PropTypes.any,
  onStartDateChange: PropTypes.func,
  endDate: PropTypes.any,
  onEndDateChange: PropTypes.func,
  showTime: PropTypes.bool
};