import { action, observable } from 'mobx';
import config from '../config'
import API from '../_app/api'
import { API_ROUTES } from '../_app/routes'
import moment from 'moment'
import 'moment/locale/pl'
import aggregationsStore from './aggregationsStore'
import { stringify } from 'query-string'

export class UsersStore {
  GENDERS = ['', 'Mężczyzni', 'Kobiety']

  @observable activePushPercent = 0
  @observable vouchersPercent = 0

  @observable loadingChurnData = true
  @observable churnData = []

  @observable usersBarChartData = []
  @observable loadingUsersBarChartData = true

  @observable usersByMediumBarChartData = []
  @observable usersByMediumBarChartDataMobile = {}
  @observable loadingUsersByMediumBarChartData = false
  @observable loadingUsersByMediumBarChartDataMobile = {}

  @observable usersPieChartData = []
  @observable loadingUsersPieChartData = true

  @observable activePushData = []
  @observable loadingPushData = true

  @observable userEvents = []
  @observable userEventsCount = 0
  @observable loadingUserEventsData = true
  @observable purchaseTotalPlnSum = 0
  @observable chosenAction = ''

  @observable actions = []
  @observable loadingActions = true

  @observable shortcutFrom = null
  @observable shortcutTo = null
  @observable shortcuts = [
    {
      label: 'Ostatnie 7 dni',
      from: moment().subtract(7, 'days').startOf('day').toDate(),
      to: moment().toDate(),
    },
    {
      label: 'Ostatnie 30 dni',
      from: moment().subtract(30, 'days').startOf('day').toDate(),
      to: moment().toDate(),
    },
    {
      label: 'Ten miesiąc',
      from: moment().startOf('month').toDate(),
      to: moment().toDate(),
    },
    {
      label: 'Poprzedni miesiąc',
      from: moment().subtract(1, 'month').startOf('month').toDate(),
      to: moment().toDate(),
    },
    {
      label: 'Ten kwartał',
      from: moment().subtract(3, 'months').startOf('month').toDate(),
      to: moment().toDate(),
    },
    {
      label: 'Poprzedni kwartał',
      from: moment().subtract(6, 'days').startOf('day').toDate(),
      to: moment().subtract(3, 'months').startOf('month').toDate(),
    },
  ]

  constructor() {}

  @action
  setShortcuts = (label) => {
    const chosenShortcut = this.shortcuts.find(
      (shortcut) => shortcut.label === label
    )
    this.shortcutFrom = chosenShortcut.from
    this.shortcutTo = chosenShortcut.to
  }

  @action
  fetchUsersBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingUsersBarChartData = true
    const { data } = await aggregationsStore.fetchStatistics({
      timeFrom,
      timeTo,
      step,
      statId: config.statIds.usersCount,
    })
    this.usersBarChartData = data.map((stat) => {
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('lll').toString()
      return stat
    })
    this.loadingUsersBarChartData = false
  }

  @action
  fetchBannerBarChartData = async (timeFrom, timeTo, step) => {
    this.loadingBannerBarChartData = true
    const { data } = await aggregationsStore.fetchStatistics({
      timeFrom,
      timeTo,
      step,
      statId: config.statIds.bannerCount,
    })
    this.bannerBarChartData = data.map((stat) => {
      const date = moment(stat.fromTs)
      stat.fromTs = date.locale('pl').format('lll').toString()
      return stat
    })
    this.loadingBannerBarChartData = false
  }

  @action
  fetchUsersByMediumBarChartData = async (
    timeFrom,
    timeTo,
    step,
    medium,
    mediumPreFix
  ) => {
    if (mediumPreFix) {
      this.loadingUsersByMediumBarChartDataMobile[medium] = true
    } else {
      this.loadingUsersByMediumBarChartData = true
    }

    let query = { timeFrom, timeTo, step }
    if (medium === 'all') {
      query.statId = config.statIds.usersUniquePageViewCount
    } else {
      query.statId = config.statIds.usersByMediumByPageViewCount
      query.medium = medium
    }
    const { data } = await aggregationsStore.fetchStatistics(query)

    if (mediumPreFix) {
      this.usersByMediumBarChartDataMobile[medium] = data.map((stat) => {
        const date = moment(stat.fromTs)
        stat.fromTs = date.locale('pl').format('lll').toString()
        return stat
      })
      this.loadingUsersByMediumBarChartDataMobile[medium] = false
    } else {
      this.usersByMediumBarChartData = data
      this.loadingUsersByMediumBarChartData = false
    }
  }

  @action
  fetchAndCountActivePushPercent = async (timeFrom, timeTo, step) => {
    const {
      data: { sum: activePushCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.activePushCount
    )
    const {
      data: { sum: usersCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.usersCount
    )
    this.activePushPercent =
      usersCount > 0 ? (activePushCount / usersCount).toFixed(2) : 0
  }

  @action
  fetchAndCountVoucherPurchasePercent = async (timeFrom, timeTo, step) => {
    const {
      data: { sum: voucherCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.purchasesWithVoucherCount
    )
    const {
      data: { sum: purchaseCount },
    } = await aggregationsStore.fetchStatisticsSum(
      timeFrom,
      timeTo,
      step,
      config.statIds.purchasesCount
    )
    this.vouchersPercent =
      purchaseCount > 0 ? (voucherCount / purchaseCount).toFixed(2) : 0
  }

  @action
  fetchUsersPieChartData = async (timeFrom, timeTo) => {
    this.loadingUsersPieChartData = true
    const query = {
      statId: config.statIds.usersByMediumByPageViewCount,
      timeFrom,
      timeTo,
      step: '1D',
      key: '$medium',
    }

    const {
      data: { sum: data },
    } = await aggregationsStore.fetchStatisticsSum(
      query.timeFrom,
      query.timeTo,
      query.step,
      query.statId,
      query.key
    )
    let total = 0
    data.forEach(({ sum }) => (total += sum))
    this.usersPieChartData = data.map(({ _id: medium, sum: value }) => ({
      medium,
      value,
      total,
    }))
    this.loadingUsersPieChartData = false
  }

  @action
  fetchChurnData = async (timeFrom, timeTo) => {
    const timeFromParsed = moment(timeFrom).startOf('day').toISOString();
    const timeToParsed = moment(timeTo).add(1, 'day').startOf('day').toISOString();
    this.loadingChurnData = true
    const queryChurn = {
      statId: config.statIds.churnCount,
      timeFrom: timeFromParsed,
      timeTo: timeToParsed,
      step: '1D',
    }

    const queryAllDevices = {
      statId: config.statIds.monthDevicesDistinct,
      timeFrom: moment(timeFromParsed).subtract(31, 'days').toISOString(),
      timeTo: moment(timeToParsed).subtract(31, 'days').toISOString(),
      step: '1D',
    }

    const { data: churn } = await aggregationsStore.fetchStatistics(queryChurn)
    const { data: all } = await aggregationsStore.fetchStatistics(
      queryAllDevices
    )

    const churnObject = {}
    churn.forEach((item) => {
      churnObject[item.fromTs] = item.value
    })

    this.churnData = all.map((item) => {
      const fromTs = moment(item.fromTs).add(31, 'days').toISOString();
      item.lost = churnObject[fromTs] ? churnObject[fromTs] : 0
      item.churn = item.value > 0 ? item.lost / item.value : 0
      item.churnPercent = item.churn * 100
      item.fromTs = moment(fromTs).locale('pl').format('lll').toString()
      return item
    })

    this.loadingChurnData = false
  }

  @action fetchUserEvents = async (query) => {
    this.userEvents = []
    this.loadingUserEventsData = true
    const { events, eventsCount } = await aggregationsStore.fetchEvents(query)
    this.userEvents = events
    this.userEventsCount = eventsCount
    this.loadingUserEventsData = false
  }

  @action fetchActions = async () => {
    this.actions = []
    this.loadingActions = true

    try {
      const { data } = await API.get(API_ROUTES.ACTIONS)
      this.actions = data.map((action) => ({
        label: action.split('_').join(' '),
        value: action,
      }))
    } catch (e) {
      console.error(e)
    }
    this.loadingActions = false
  }

  @action setChosenAction = (action) => {
    this.chosenAction = action
  }

  @action fetchPurchaseTotalPln = async (query) => {
    try {
      const { data } = await API.get(
        `${API_ROUTES.PURCHASE_PLN_TOTAL}?${stringify(query)}`
      )
      this.purchaseTotalPlnSum = data
    } catch (e) {
      console.error(e)
    }
  }

  @action
  fetchPushData = async (timeFrom, timeTo, step) => {
    this.loadingPushData = true

    const query = {
      timeFrom,
      timeTo,
      step: step || '1D'
    }
    const pushStat = config.statIds.activePPGCount
    const usersStat = config.statIds.usersCount


    if (!timeFrom || !timeTo) {
      query.timeFrom = moment().subtract(30, 'days').toISOString()
      query.timeTo = moment().toISOString()
    }

    const { data: pushData } = await aggregationsStore.fetchStatistics({ ...query, statId: pushStat })
    const { data: usersData } = await aggregationsStore.fetchStatistics({ ...query, statId: usersStat })

    const usersObject = {}
    usersData.forEach((item) => {
      usersObject[item.fromTs] = item.value
    })

    this.activePushData = pushData.map((item) => {
      item.usersCount = usersObject[item.fromTs]
      item.pushCount = item.value
      item.percent = item.usersCount > 0 ? (item.pushCount/item.usersCount) * 100 : 0
      item.fromTs = moment(item.fromTs).locale('pl').format('lll').toString()
      return item
    })

    this.loadingPushData = false
  }

}

export default new UsersStore()
