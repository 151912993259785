import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip } from 'recharts';
import { Filters, showLoaderOrPlaceHolder } from '../../../components/helpers/chartHelpers';
import moment from 'moment';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { inject, observer } from 'mobx-react';
import * as PropTypes from 'prop-types';


const charts = [
  // { label: 'Typ dostawy', collectionName: 'deliveryType' },
  { label: 'Sposób płatności', collectionName: 'paymentType' },
  { label: 'Dostawca płatności', collectionName: 'paymentProvider' },
];


const PaymentCharts = ({ personaStore }) => {
  const { shortcutFrom: from, shortcutTo: to, paymentsChartData, fetchPaymentsChartData, loadingPaymentsChartData } = personaStore;

  const timeFrom = from ? moment(from).toDate() : moment().subtract(30, 'day').startOf('day').toDate();
  const timeTo = to ? moment(to).toDate() : moment().endOf('day').toDate();
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);

  useEffect(() => {
    if(from && to) {
      setStartDate(from)
      setEndDate(to)
    }
  }, [from, to])

  useEffect(() => {
    fetchPaymentsChartData(startDate, endDate);
  }, [startDate, endDate])

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        {
          charts.map((chart, index) => {
            if(loadingPaymentsChartData || paymentsChartData[chart.collectionName].length === 0) {
              return (
                <div key={`payment-chart-${index}-loader`} style={{ minWidth: '28vw', textAlign: 'center' }}>
                  {showLoaderOrPlaceHolder(loadingPaymentsChartData)}
                </div>
              )
            }
            return (
              <div key={`payment-chart-${index}`} style={{ minWidth: '28vw', textAlign: 'center' }}>
                <div>{chart.label}</div>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie fill="#003f5c" data={paymentsChartData[chart.collectionName]}/>
                    <Tooltip/>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            );
          })
        }
      </div>
      <div style={{ width: '50%', margin: 'auto' }}>
        <Filters>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
            showTime={false}
          />
        </Filters>
      </div>
    </div>
  );
};

export default inject('personaStore')(observer(PaymentCharts));

PaymentCharts.propTypes = {
  personaStore: PropTypes.shape({
    shortcutFrom: PropTypes.string,
    shortcutTo: PropTypes.string,
    paymentsChartData: PropTypes.any,
    fetchPaymentsChartData: PropTypes.any,
    loadingPaymentsChartData: PropTypes.bool
  })
}