import { inject, observer } from "mobx-react";
import React, { Component } from "react";

import LoginPage from "./Components/LoginPage.js";
import * as PropTypes from "prop-types";

@inject("authStore")
@observer
class LoginPageContainer extends Component {
  render() {
    return (
      <div style={{ minHeight: "calc(100vh - 194px)" }}>
        <LoginPage
          login={this.props.authStore.login}
          authError={this.props.authStore.authError}
        />
      </div>
    );
  }
}

LoginPageContainer.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    login: PropTypes.func,
    authError: PropTypes.string
  })
};

export default LoginPageContainer;
