import React from 'react';
import Rollbar from '../../_app/rollbar';
import PropTypes from "prop-types";

export default class RollbarError extends React.Component {
  state = { hasError: false };

  componentDidCatch(error) {
    this.setState({ hasError: true });
    Rollbar.error(error);
    this.props.history.push('/error');
  }

  render() {
    return this.props.children;
  }
}

RollbarError.propTypes = {
  history: PropTypes.any,
  children: PropTypes.any
}