import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Subtitle, Title } from "../../components/helpers/chartHelpers";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import TopProductsGlobalTable from "./Components/TopProductsGlobalTable";
import TopProductsPerMaskTable from "./Components/TopProductsPerMaskTable";
import TopCategoriesGlobalTable from "./Components/TopCategoriesGlobalTable";
import TopCategoriesByMaskTable from "./Components/TopCategoriesByMaskTable";
import CategoriesMaskSelect from "./Components/CategoriesMaskSelect";
import ProductsMaskSelect from './Components/ProductsMaskSelect';
import TopProductsMaskTable from './Components/TopProductsMaskTable';
import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

@observer
@inject('productsStore')
class ProductsPageContainer extends Component {

  async componentDidMount() {

    await this.props.productsStore.fetchMasks()
    await this.props.productsStore.fetchProductsTop()
    await this.props.productsStore.fetchMasksData()
    await this.props.productsStore.fetchTopProductsCategories()
    await this.props.productsStore.fetchTopProductsCategoriesPerMask()
  }

  render() {
    return (
        <div>
          <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
            <GridItem xs={12}>
              <Title color="#212121">Produkty</Title>
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">Top 100 kupowanych produktów w bieżącym miesiącu (globalnie)</Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <TopProductsGlobalTable />
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">Top 100 kupowanych produktów w bieżącym miesiącu (dla maski)</Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <TopProductsPerMaskTable />
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">
                Top kategorie w bieżącym miesiącu (globalnie)
                <TooltipUI style={{ paddingTop: 3, marginLeft: 9 }} title="Wyznaczone na podstawie top 100 produktów globalnie">
                  <InfoIcon htmlColor="#212121"/>
                </TooltipUI>
              </Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <TopCategoriesGlobalTable />
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">
                Top kategorie w bieżącym miesiącu (dla maski)
                <TooltipUI style={{ paddingTop: 3, marginLeft: 9 }} title="Wyznaczone na podstawie top 100 produktów dla każdej maski">
                  <InfoIcon htmlColor="#212121"/>
                </TooltipUI>
              </Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <CategoriesMaskSelect />
            </GridItem>
            <GridItem xs={12}>
              <TopCategoriesByMaskTable/>
            </GridItem>
            <GridItem xs={12}>
              <Subtitle color="#212121">Top produkty (dla maski)</Subtitle>
            </GridItem>
            <GridItem xs={12}>
              <ProductsMaskSelect />
            </GridItem>
            <GridItem xs={12}>
              <TopProductsMaskTable />
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default ProductsPageContainer;

ProductsPageContainer.propTypes = {
  productsStore: PropTypes.shape({
    fetchProductsTop: PropTypes.func,
    fetchMasksData: PropTypes.func,
    fetchMasks: PropTypes.func,
    fetchTopProductsCategories: PropTypes.func,
    fetchTopProductsCategoriesPerMask: PropTypes.func,
    fetchTopProductsPerMask: PropTypes.func,
  })
}

