import React from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Button from "@material-ui/core/Button";

function PersonaShortcuts({ personaStore }) {
  return (
    <div>
      <h2 style={{ margin: '10px 0px 30px 10px' }}>Skróty</h2>
      {personaStore.shortcuts.map((shortcut) => {
        return <Button onClick={() => personaStore.setShortcuts(shortcut.label) } key={shortcut.label}>{shortcut.label}</Button>
      })}
    </div>
  )
}
export default inject('personaStore')(observer(PersonaShortcuts));

PersonaShortcuts.propTypes = {
  personaStore: PropTypes.shape({
    shortcuts: PropTypes.array,
    setShortcuts: PropTypes.func
  })
}

