import React, { Component } from "react";

import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Subtitle, Title } from "../../components/helpers/chartHelpers";
import PurchaseLineChart from "./Components/PurchaseLineChart";
import HoursTable from "./Components/HoursTable";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import HourlyComparison from "./Components/HourlyComparison";
import IncomePerMaskTable from "./Components/IncomePerMaskTable";
import PurchasesPerMaskChart from "./Components/PurchasesPerMaskChart";
import AvgTotalPricePerMaskChart from "./Components/AvgTotalPricePerMaskChart";
import TopBasicInfo from "./Components/TopBasicInfo";
import PurchaseDailyLineChart from "./Components/PurchaseDailyLineChart";
import PurchaseShortcuts from "./Components/PurchaseShortcuts";
import DailySum from './Components/DailySum';
import moment from 'moment';

const HourlyLabel = inject('purchaseStore')(observer(({ purchaseStore: { hourlyDate } }) => (
  <Subtitle color="#212121">
    {`Godzinowo (${moment(hourlyDate).format('DD/MM/YYYY')}) `}
  </Subtitle>)));


HourlyLabel.propTypes = {
  purchaseStore: PropTypes.shape({
    hourlyDate: PropTypes.any
  })
}

@observer
@inject('purchaseStore')
class SalesPageContainer extends Component {

  componentDidMount() {
    this.props.purchaseStore.fetchIncomePerMask()
    this.props.purchaseStore.fetchPurchasesPerMask()
    this.props.purchaseStore.fetchAvgTotalPricePerMask()
  }

  render() {
    return (
      <div>
        <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
          <GridItem xs={12}>
            <Title color="#212121">Sprzedaż</Title>
          </GridItem>
          <GridItem xs={12}>
            <DailySum />
          </GridItem>
          <GridItem xs={12}>
            <PurchaseDailyLineChart />
          </GridItem>
          <GridItem xs={12}>
            <Subtitle color="#212121">Godzinowo</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <TopBasicInfo />
          </GridItem>
          <GridItem xs={9}>
            <PurchaseLineChart />
          </GridItem>
          <GridItem xs={3}>
            <HourlyComparison />
          </GridItem>
          <GridItem xs={6}>
            <HourlyLabel />
          </GridItem>
          <GridItem xs={6}>
            <Subtitle color="#212121">W podziale na maski</Subtitle>
          </GridItem>
          <GridItem xs={6}>
            <HoursTable />
          </GridItem>
          <GridItem xs={6}>
            <IncomePerMaskTable />
          </GridItem>
          <GridItem xs={6}>
            <Subtitle color="#212121">Ilość transakcji na maskę</Subtitle>
          </GridItem>
          <GridItem xs={6}>
            <Subtitle color="#212121">Średnia wartość koszyka na maskę</Subtitle>
          </GridItem>
          <GridItem xs={12}>
            <PurchaseShortcuts />
          </GridItem>
          <GridItem xs={6}>
            <PurchasesPerMaskChart />
          </GridItem>
          <GridItem xs={6}>
            <AvgTotalPricePerMaskChart />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default SalesPageContainer;

SalesPageContainer.propTypes = {
  purchaseStore: PropTypes.shape({
    fetchIncomePerMask: PropTypes.func,
    fetchAvgTotalPricePerMask: PropTypes.func,
    fetchPurchasesPerMask: PropTypes.func,
    hourlyDate: PropTypes.any,
  })
}