import { Modal } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

export const ImagePopup = ({ handleClose, open, imageUrl }) => (
  <Modal
    open={open}
    onClose={handleClose}
  >
    <div onClick={handleClose} style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
      <img style={{ maxHeight: '90vh', maxWidth: '90vw' }} src={imageUrl} />
    </div>
  </Modal>
);

ImagePopup.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  imageUrl: PropTypes.string,
};